import { ContractRole } from '@paid-ui/enums/contract';
import { PartyLinkingStatus, PartyType } from '@paid-ui/enums/party';
import { ApprovalStatus } from '@paid-ui/enums/snapshot';
import { type ContractParticipant, type ContractParticipantParty } from '@paid-ui/types/parties';
import {
  getContractParticipantParties,
  getInvitee,
  getInviteeParty,
  getInviter,
  getInviterParty,
  getLastReviewParty,
  getPayee,
  getPayeeParty,
  getPayer,
  getPayerParty,
  getSuperintendentParty,
} from '@paid-ui/utils/parties';
import { sumBy } from 'lodash';

import { userManager } from '../../user';
import { contractManager } from '../model';

export const saveParticipants = (participants: ContractParticipant[] = []) => {
  const payee = getPayee(participants);
  const payer = getPayer(participants);
  const inviter = getInviter(participants);
  const invitee = getInvitee(participants);

  contractManager.payee = payee;
  contractManager.payer = payer;
  contractManager.invitee = invitee;
  contractManager.inviter = inviter;
  contractManager.participants = participants;
};

export const saveParticipantParties = (parties: ContractParticipantParty[]) => {
  const { profile } = userManager;

  const inviteeParty = getInviteeParty(parties);
  const inviterParty = getInviterParty(parties);

  const payeeParty = getPayeeParty(parties);
  const payerParty = getPayerParty(parties);
  const superintendent = getSuperintendentParty(parties);
  const lastReviewParty = getLastReviewParty(parties);
  const { theParty, theOtherParty } = getContractParticipantParties(
    parties,
    profile?.groupRelation?.group?.id,
  );

  const isInvitee = profile?.groupRelation?.group.id === inviteeParty?.userGroup?.id;
  const isInviter = profile?.groupRelation?.group.id === inviterParty?.userGroup?.id;
  const isPayee = profile?.groupRelation?.group.id === payeeParty?.userGroup?.id;
  const isPayer = profile?.groupRelation?.group.id === payerParty?.userGroup?.id;
  const isSuperintendent = profile?.groupRelation?.group.id === superintendent?.userGroup?.id;
  const isArchitect = isSuperintendent;
  const isPayerHasAccount = !!payerParty?.account;
  const numberOfParticipants =
    (payeeParty?.usersInfo?.length ?? 0) +
    (payerParty?.usersInfo?.length ?? 0) +
    (superintendent?.usersInfo?.length ?? 0);

  const joinedList = parties.filter((party) => party.linkingStatus !== PartyLinkingStatus.UNLINKED);

  const numberOfJoined = sumBy(joinedList, (item) => item.usersInfo?.length ?? 0);
  const signedList = parties.filter((party) => party.linkingStatus === PartyLinkingStatus.SIGNED);
  const numberOfSigned = sumBy(signedList, (item) => item.usersInfo?.length ?? 0);
  const approvedList = parties.filter(
    (party) => party.snapshotStatus?.approvalStatus === ApprovalStatus.ACCEPT,
  );
  const numberOfApproved = sumBy(approvedList, (item) => item.usersInfo?.length ?? 0);

  contractManager.party = theParty ?? null;
  contractManager.theOtherParty = theOtherParty ?? null;
  contractManager.partyName = theParty?.displayName ?? '';
  contractManager.theOtherPartyName = theOtherParty?.displayName ?? '';

  contractManager.payeeParty = payeeParty;
  contractManager.payerParty = payerParty;
  contractManager.inviteeParty = inviteeParty;
  contractManager.inviterParty = inviterParty;
  contractManager.superintendentParty = superintendent;
  contractManager.superintendentPartyName = [
    superintendent?.usersInfo?.[0]?.firstName,
    superintendent?.usersInfo?.[0]?.lastName,
  ].join(' ');

  contractManager.isPayee = isPayee;
  contractManager.isPayer = isPayer;
  contractManager.isInvitee = isInvitee;
  contractManager.isInviter = isInviter;
  contractManager.isArchitect = isArchitect;
  contractManager.isSuperintendent = isSuperintendent;
  contractManager.isPayerHasAccount = isPayerHasAccount;
  contractManager.participantParties = parties;
  contractManager.numberOfParticipants = numberOfParticipants;
  contractManager.numberOfJoined = numberOfJoined;
  contractManager.numberOfSigned = numberOfSigned;
  contractManager.numberOfApproved = numberOfApproved;

  contractManager.currentParty = isSuperintendent
    ? superintendent
    : isPayee
    ? payeeParty
    : payerParty;
  contractManager.lastReviewParty = lastReviewParty;
};
