import { ContractState } from '@paid-ui/enums/contract';
import { PartyLinkingStatus } from '@paid-ui/enums/party';
import { ContractAction } from '@paid-ui/enums/requiring-action';
import dayjs from 'dayjs';

import { contractManager } from '../model';

export const savePeriodicPermissions = () => {
  const {
    contractState,
    hasAllSigned,
    commencementDate,
    actualCompletionDate,
    party,
    currentParty,
    lastReviewParty,
    isPayee,
    isInvitee,
    inviteeParty,
    isInviter,
    retainableInfo,
    statuses: { isPrimeContract, isOffPlatformContract, isContractExecuted, isDraftContract },
  } = contractManager;

  const isContractSubmitted = contractState === ContractState.SUBMITTED && !isDraftContract;

  const isCommencementDateBeforeToday =
    !!commencementDate && dayjs(commencementDate).isBefore(dayjs().startOf('day'));

  const canAbandonContract =
    isInviter &&
    !isPrimeContract &&
    !isDraftContract &&
    [ContractState.SUBMITTED, ContractState.RESUBMITTED, ContractState.REJECTED].includes(
      contractState,
    );

  const canCreateClaim = false;

  const canClaimRetention =
    isPayee && isContractExecuted && !!retainableInfo?.claimable?.excludeGstAmount;

  const actions = currentParty?.role?.actions ?? [];

  const canCreateVariation =
    actions.includes(ContractAction.VARIATION_SUBMIT) &&
    !isDraftContract &&
    isContractExecuted &&
    hasAllSigned;

  const canResendInvitation = isPrimeContract
    ? isContractSubmitted &&
      isInviter &&
      inviteeParty?.linkingStatus === PartyLinkingStatus.UNLINKED
    : isContractSubmitted && isInviter && inviteeParty?.linkingStatus === PartyLinkingStatus.LINKED;

  const canReviewOnPlatformContract = !isOffPlatformContract && !!currentParty && isInvitee;

  const canReviewOffPlatformContract =
    isOffPlatformContract && !!currentParty && currentParty.id !== lastReviewParty?.id;

  const canReviewContract =
    (canReviewOnPlatformContract || canReviewOffPlatformContract) &&
    [ContractState.SUBMITTED, ContractState.RESUBMITTED].includes(contractState);

  const canSendCommencementNotice =
    !isDraftContract && isPayee && isContractExecuted && !commencementDate;

  const canSendCompletionNotice =
    !isDraftContract &&
    isPayee &&
    isContractExecuted &&
    !actualCompletionDate &&
    isCommencementDateBeforeToday;

  const canSignContract =
    isPrimeContract &&
    !isOffPlatformContract &&
    contractState === ContractState.ACCEPTED &&
    party?.linkingStatus !== PartyLinkingStatus.SIGNED;

  const canViewContract = !isDraftContract;

  contractManager.permissions = {
    canAbandonContract,
    canCreateClaim,
    canCreateVariation,
    canResendInvitation,
    canReviewContract,
    canSendCommencementNotice,
    canSendCompletionNotice,
    canSignContract,
    canViewContract,
    canClaimRetention,
    canHaltSigning: false,
  };
};
