import { plusOrMinus } from '@paid-ui/constants';
import { ContractAction } from '@paid-ui/enums/requiring-action';
import {
  AdjustmentType,
  PriceVariationMethod,
  type VariationStage,
} from '@paid-ui/enums/variation';
import { WorkItemStatus } from '@paid-ui/enums/work-item';
import { userManager } from '@paid-ui/models/user';
import {
  type ContractParticipant,
  type ParticipantParty,
  type RequiringAction,
} from '@paid-ui/types';
import { type Adjustment, type Variation } from '@paid-ui/types/variations';
import { type WorkItem } from '@paid-ui/types/work-items';
import { utc2Mel } from '@paid-ui/utils/datetime';
import { formatDisplayName } from '@paid-ui/utils/fid';
import { groupBy, sortBy, sumBy } from 'lodash';

import { contractManager } from '../model';
import { type Contract } from '../types';

export const addDisplayNameToVariation = (variation: Variation, index: number): Variation => ({
  ...variation,
  displayName:
    variation.title || variation.description || `Variation ${variation.index ?? index + 1}`,
  description: variation.variationDetails
    .map((item) => item.description)
    .filter(Boolean)
    .join(', '),
  amount:
    plusOrMinus[variation.totalPriceImpact ?? PriceVariationMethod.NONE] *
    Number(variation.totalVariationPrice),
});

export const addDisplayNameToAdjustment = (adjustment: Adjustment, index: number): Adjustment => ({
  ...adjustment,
  displayName:
    adjustment.type === AdjustmentType.ADJUSTMENT_PRIME
      ? `Prime cost adjustment ${adjustment.index ?? index + 1}`
      : `Provisional sum adjustment ${adjustment.index ?? index + 1}`,
  description: adjustment.items
    .map((item) => item.description)
    .filter(Boolean)
    .join(', '),
  amount: sumBy(
    adjustment.items,
    (item) =>
      plusOrMinus[item.netAdjustmentImpact ?? PriceVariationMethod.NONE] *
      Number(item.netAdjustment),
  ),
});

export const sortVariation = (variation: Variation | Adjustment) => {
  return variation.createdAt ? utc2Mel(variation.createdAt).valueOf() : 0;
};

export const saveVariationsAndAdjustments = (
  contract: Contract,
  requiringActions: RequiringAction[] = [],
) => {
  if (!contract) return;
  const { variations = [], adjustments = [] } = contract;
  const variationsWithName = variations.map(addDisplayNameToVariation);
  const numberOfVariations = variations.length;
  const numberOfAdjustments = adjustments.length;

  contractManager.variations = variationsWithName;
  contractManager.numberOfVariations = numberOfVariations;
  contractManager.numberOfAdjustments = numberOfAdjustments;

  if (adjustments.length === 0) {
    contractManager.variationsAndAdjustments = sortBy(variationsWithName, sortVariation);
    contractManager.numberOfVariationsAndAdjustments = numberOfVariations;
    return;
  }

  const {
    ADJUSTMENT_PRIME: primeAdjustments = [],
    ADJUSTMENT_PROVISIONAL: provisionalAdjustments = [],
  } = groupBy(adjustments, (value) => value.type);
  const primeAdjustmentsWithName = primeAdjustments.map(addDisplayNameToAdjustment);
  const provisionalAdjustmentsWithName = provisionalAdjustments.map(addDisplayNameToAdjustment);

  contractManager.numberOfVariationsAndAdjustments = numberOfVariations + numberOfAdjustments;
  contractManager.primeCostAdjustments = sortBy(primeAdjustmentsWithName, sortVariation);
  contractManager.provisionalSumAdjustments = sortBy(provisionalAdjustmentsWithName, sortVariation);
  contractManager.adjustments = sortBy(
    [...primeAdjustmentsWithName, ...provisionalAdjustmentsWithName],
    sortVariation,
  );
  contractManager.variationsAndAdjustments = sortBy(
    [...variationsWithName, ...primeAdjustmentsWithName, ...provisionalAdjustmentsWithName],
    sortVariation,
  );
};

export const savePeriodicVariations = (
  variations: WorkItem[] = [],
  participantParties: ParticipantParty[] = [],
) => {
  const { profile } = userManager;
  const currentParticipant = participantParties.find(
    (participant) => profile?.groupRelation?.group.id === participant?.userGroup?.id,
  );
  const isSubmitter = currentParticipant?.role?.actions.includes(ContractAction.VARIATION_SUBMIT);
  const variationsWithName = variations.map((variation) => ({
    // ...variation,
    id: variation.id,
    claimId: variation.id,
    attachments: variation.attachments,
    displayName: variation.displayName || formatDisplayName(variation.code, variation.name),
    description: variation.description,
    totalPriceImpact: PriceVariationMethod.INCREASE,
    totalVariationPrice: Number(variation.amount),
    variationStage: variation.workItemStatus as unknown as VariationStage,
    inviteeParty: isSubmitter ? null : currentParticipant,
    inviterParty: isSubmitter ? currentParticipant : null,
    currentAssessedTaxableAmount: variation.currentAssessedTaxableAmount,
    stateHistory: variation.stateHistory,
    reasonForRejection: sortBy(variation?.stateHistory ?? [], (item) =>
      item.createdAt.valueOf(),
    ).at(-1)?.comments,
  })) as Variation[];
  const numberOfVariations = variations.length;
  contractManager.variations = variationsWithName;
  contractManager.numberOfVariations = numberOfVariations;
  contractManager.numberOfAdjustments = 0;
  contractManager.adjustments = [];
  contractManager.variationsAndAdjustments = sortBy(variationsWithName, sortVariation).reverse();
  contractManager.numberOfVariationsAndAdjustments = numberOfVariations;
};
