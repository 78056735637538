export enum RequiringActionType {
  SUBMIT_CLAIM = 'SUBMIT_CLAIM',
  UPDATE = 'UPDATE',
  REVIEW = 'REVIEW',
  PAY = 'PAY',
  SIGN = 'SIGN',
  JOIN = 'JOIN',
}

export enum RequiringActionStatus {
  PENDING = 'PENDING',
  COMPLETED = 'COMPLETED',
}

export enum RequiringActionFeatureType {
  CONTRACT = 'CONTRACT',
  VARIATION = 'VARIATION',
  ADJUSTMENT = 'ADJUSTMENT',
  CLAIM = 'CLAIM',
  EARLY_RELEASE_REQUEST = 'EARLY_RELEASE_REQUEST',
}

export enum ContractAction {
  CONTRACT_REVIEW = 'CONTRACT_REVIEW',
  CONTRACT_AMEND = 'CONTRACT_AMEND',
  CLAIM_SUBMIT = 'CLAIM_SUBMIT',
  CLAIM_REVIEW = 'CLAIM_REVIEW',
  CLAIM_ASSESSED = 'CLAIM_ASSESSED',
  VARIATION_SUBMIT = 'VARIATION_SUBMIT',
  VARIATION_REVIEW = 'VARIATION_REVIEW',
  CLAIM_PAY = 'CLAIM_PAY',
}
