import { AttachmentCategory } from '@paid-ui/enums/attachment';
import { type Attachment, type BankGuaranteeAttachment } from '@paid-ui/schemas/zod/attachment';
import { groupBy } from 'lodash';

import { contractManager } from '../model';

export const saveAttachments = (
  allAttachments: Attachment[],
  bankGuarantees?: BankGuaranteeAttachment[],
) => {
  const {
    pdfContracts = [],
    notices = [],
    attachments = [],
    certificates = [],
  } = groupBy(allAttachments, ({ category }) => {
    switch (category) {
      case AttachmentCategory.OFF_PLATFORM: {
        return 'pdfContracts';
      }
      case AttachmentCategory.NOTICES: {
        return 'notices';
      }
      case AttachmentCategory.CERTIFICATES:
      case AttachmentCategory.BANK_GUARANTEE: {
        return 'certificates';
      }
      default: {
        return 'attachments';
      }
    }
  });

  contractManager.pdfContract = pdfContracts.at(0) ?? null;
  contractManager.notices = notices;
  contractManager.attachments = attachments;
  contractManager.certificates = certificates;
  contractManager.bankGuarantees = bankGuarantees ?? [];
};
