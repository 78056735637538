import { type ClaimsTabName } from '@paid-ui/blocks/claims-tab/tabs';
import { PaymentClaimType } from '@paid-ui/constants';
import { ClaimSource, type ClaimStatus, type DerivedClaimStatus } from '@paid-ui/enums/claim';
import { type ContractType } from '@paid-ui/enums/contract';
import { EvidenceCategory } from '@paid-ui/enums/evidence';
import { DefaultPaymentActionType, PaymentStageName } from '@paid-ui/enums/payment';
import { type PaymentSecuredStatus } from '@paid-ui/enums/payment';
import { RequiringActionFeatureType } from '@paid-ui/enums/requiring-action';
import { browserEnvs } from '@paid-ui/env';
import { type ClaimDetailsData } from '@paid-ui/queries/claim-details';
import { type LinkedPaymentData } from '@paid-ui/queries/linked-payments';
import { type Attachment } from '@paid-ui/schemas/zod/attachment';
import { type Evidence } from '@paid-ui/schemas/zod/evidence';
import {
  type Adjustment,
  type ContractPrice,
  type ContractSummary,
  type Variation,
} from '@paid-ui/types';
import { type EarlyReleaseRequest } from '@paid-ui/types/early-release';
import { type ParticipantParty } from '@paid-ui/types/parties';
import {
  type ClaimDiscount,
  type PaymentBreakdown,
  type PaymentHistory,
  type PaymentStage,
  type TaxableAmount,
} from '@paid-ui/types/payments';
import { type RequiringAction } from '@paid-ui/types/requiring-actions';
import { type WorkItemPayment, type WorkItemPaymentLine } from '@paid-ui/types/work-items';
import {
  formatAddress,
  getContractParticipantParties,
  getInviteeParty,
  getInviterParty,
  getPayeeParty,
  getPayerParty,
} from '@paid-ui/utils';
import { getPreSignedUrls } from '@paid-ui/utils/attachment';
import { utc2Mel } from '@paid-ui/utils/datetime';
import { groupBy, sortBy } from 'lodash';
import { proxy } from 'valtio';
import { devtools, subscribeKey } from 'valtio/utils';

import { contractManager, type DefaultAction, formatProgressPayment } from './contract';
import { savePreSignedUrls } from './pre-signed-url';
import { projectManager } from './project';
import { userManager } from './user';

const { pceBucketName } = browserEnvs;

export const paymentRequiringActionFeatureTypeSet = new Set([
  RequiringActionFeatureType.CLAIM,
  RequiringActionFeatureType.EARLY_RELEASE_REQUEST,
]);

export type DefaultPaymentAction = DefaultAction<DefaultPaymentActionType>;

export interface Payment extends PaymentStage {
  id: string;
  displayName: string;
  displayAmount: number;
  description?: string;
  workItemPaymentNumber: string;
  workItemPayment?: WorkItemPayment;
  hasParent: boolean;
  hasLinks: boolean;
  hasClaim: boolean;
  claimId: string;
  claimAmount: number;
  taxableAmount: TaxableAmount;
  retentionAmount: number;
  retentionTaxableAmount: TaxableAmount;
  claimState?: ClaimStatus;
  securedStatus?: PaymentSecuredStatus;
  dueDate?: string;
  dueInDays: number;
  submissionDueDate?: string;
  paymentTermsInDays: number;
  earlyReleaseDiscountRate?: number;
  breakdown?: PaymentBreakdown;
  earlyReleaseRequests: EarlyReleaseRequest[];
  numberOfVariations: number;
  numberOfLinkedPayments: number;
  numberOfDiscounts: number;
  lineWorkItems: WorkItemPaymentLine[];
  variationWorkItems: WorkItemPaymentLine[];
  linkedPayment?: LinkedPaymentData | null;
  consolidatedClaimEndDate?: string;
  contract: {
    id: string;
    contractType: ContractType | '';
    contractName: string;
    fullContractName: string;
    suburbAndState: string;
    address: string;
    price: ContractPrice;
    viewAs?: ClaimsTabName;
    partyName: string;
    party: ParticipantParty | null;
    theOtherPartyName: string;
    theOtherParty: ParticipantParty | null;
    isPayer: boolean;
    isPayee: boolean;
    isInviter: boolean;
    isInvitee: boolean;
    isSuperintendent: boolean;
    payerParty: ParticipantParty | null;
    payeeParty: ParticipantParty | null;
    inviteeParty: ParticipantParty | null;
    inviterParty: ParticipantParty | null;
    participantParties: ParticipantParty[];
    superintendentParty: ParticipantParty | null;
    isContractExecuted: boolean;
  };
  defaultActions: DefaultPaymentAction[];
  requiringActions: RequiringAction[];
  statuses: {
    isPeriodic: boolean;
    isOffPlatform: boolean;
    isApproved: boolean;
    isProvisional: boolean;
    isAssessed: boolean;
    isOverdue: boolean;
    isSecured: boolean;
    isPartSecured: boolean;
    isFullySecured: boolean;
    isPaid: boolean;
    isPartPaid: boolean;
    isFullPaid: boolean;
    isRetention: boolean;
  };
  permissions: {
    canClaim: boolean;
    canUpdate: boolean;
    canLink: boolean;
    canSecure: boolean;
    canRelease: boolean;
    canMarkAsPaid: boolean;
    canResubmit: boolean;
    canReview: boolean;
    canPay: boolean;
    canEdit: boolean;
  };
  filters: Set<DerivedClaimStatus>;
  paymentBreakdown?: PaymentBreakdown | null;
  histories?: PaymentHistory[];
  claimHistories?: PaymentHistory[];
  paymentHistories?: PaymentHistory[];
  contractSummary?: ContractSummary | null;
  variations?: Variation[];
  adjustments?: Adjustment[];
  evidences?: Evidence[];
  documents?: Evidence[];
  photosAndVideos?: Evidence[];
  preSignedUrls?: Record<string, string>;
  attachments?: Attachment[];
  claimPayment?: PaymentStage | null;
  discounts?: ClaimDiscount[];
  earlyReleaseDiscounts?: ClaimDiscount[];
  snapshot: Record<string, any> | null;
}

const initialState: Payment = {
  id: '',
  stage: PaymentStageName.OTHER,
  customName: '',
  displayName: '',
  workItemPaymentNumber: '',
  percentOfContractSum: 0,
  amount: 0,
  taxableAmount: {
    amount: 0,
    excludeGstAmount: 0,
    gstAmount: 0,
  },
  displayAmount: 0,
  hasParent: false,
  hasLinks: false,
  hasClaim: false,
  claimId: '',
  claimAmount: 0,
  retentionAmount: 0,
  retentionTaxableAmount: {
    amount: 0,
    excludeGstAmount: 0,
    gstAmount: 0,
  },
  dueInDays: 0,
  paymentTermsInDays: 14,
  earlyReleaseDiscountRate: 0,
  earlyReleaseRequests: [],
  numberOfVariations: 0,
  numberOfDiscounts: 0,
  numberOfLinkedPayments: 0,
  lineWorkItems: [],
  variationWorkItems: [],
  contract: {
    id: '',
    address: '',
    contractType: '',
    contractName: '',
    fullContractName: '',
    suburbAndState: '',
    price: {
      initial: 0,
      current: 0,
      paid: {
        amount: 0,
        percentage: 0,
      },
      remaining: {
        amount: 0,
        percentage: 0,
      },
    },
    party: null,
    partyName: '',
    theOtherPartyName: '',
    theOtherParty: null,
    isPayer: false,
    isPayee: false,
    isInviter: false,
    isInvitee: false,
    isSuperintendent: false,
    payerParty: null,
    payeeParty: null,
    inviteeParty: null,
    inviterParty: null,
    participantParties: [],
    superintendentParty: null,
    isContractExecuted: false,
  },
  linkedPayment: null,
  defaultActions: [
    {
      type: DefaultPaymentActionType.CLAIM,
      text: 'Claim',
      hidden: true,
    },
    {
      type: DefaultPaymentActionType.UPDATE,
      text: 'Update',
      hidden: true,
    },
    {
      type: DefaultPaymentActionType.LINK,
      text: 'Link',
      hidden: true,
    },
    {
      type: DefaultPaymentActionType.SECURE,
      text: 'Secure',
      hidden: true,
    },
    {
      type: DefaultPaymentActionType.RELEASE,
      text: 'Get InstaPaid',
      hidden: true,
    },
    {
      type: DefaultPaymentActionType.MARK_AS_PAID,
      text: 'Mark as paid',
      hidden: true,
    },
  ],
  paymentBreakdown: null as PaymentBreakdown | null,
  claimHistories: [],
  paymentHistories: [],
  requiringActions: [],
  variations: [],
  adjustments: [],
  discounts: [],
  evidences: [],
  documents: [],
  photosAndVideos: [],
  attachments: [],
  preSignedUrls: {},
  statuses: {
    isPeriodic: false,
    isOffPlatform: false,
    isProvisional: false,
    isAssessed: false,
    isApproved: false,
    isOverdue: false,
    isSecured: false,
    isPartSecured: false,
    isFullySecured: false,
    isPaid: false,
    isPartPaid: false,
    isFullPaid: false,
    isRetention: false,
  },
  permissions: {
    canClaim: false,
    canUpdate: false,
    canLink: false,
    canSecure: false,
    canRelease: false,
    canMarkAsPaid: false,
    canResubmit: false,
    canReview: false,
    canPay: false,
    canEdit: false,
  },
  filters: new Set(),
  snapshot: null,
};

export const paymentManager = proxy(initialState);

devtools(paymentManager, {
  name: 'Current Payment',
  enabled: false,
});

export const resetPayment = () => {
  Object.assign(paymentManager, initialState);
};

export const setLinkedPayment = (data?: LinkedPaymentData) => {
  paymentManager.linkedPayment = data;
};

export const savePeriodicWorkItems = (payment?: WorkItemPayment) => {
  const { displayName, statuses } = paymentManager;
  if (!statuses.isPeriodic) return;

  const allWorkItems = payment?.workItemPaymentLines || [];
  const { LINE: lineWorkItems = [], VARIATION: variationWorkItems = [] } = groupBy(
    sortBy(allWorkItems, (item) => item.workItem.code),
    (item) => item.workItem.type,
  );
  paymentManager.displayName = payment?.endDate
    ? utc2Mel(payment.endDate).format('MMMM')
    : displayName;
  paymentManager.workItemPayment = payment;
  paymentManager.attachments = payment?.attachments ?? [];
  paymentManager.workItemPaymentNumber = payment?.workItemPaymentNumber ?? '';
  paymentManager.lineWorkItems = lineWorkItems;
  paymentManager.variationWorkItems = variationWorkItems;
};

export const fixNestedObjectAssign = (payment: Payment) => {
  paymentManager.claim = payment.claim;
  paymentManager.parent = payment.parent;
  paymentManager.workItemPayment = payment.workItemPayment;
  paymentManager.defaultActions = payment.defaultActions;
  paymentManager.description = payment?.description;
};

export const saveContractSummary = (contract: ContractSummary) => {
  const { profile } = userManager;
  const { id, address, contractType, participantParties } = contract;

  const { suburb, state } = contract.address;
  const formattedAddress = formatAddress(address, true);

  const { theParty, theOtherParty } = getContractParticipantParties(
    participantParties,
    profile?.groupRelation?.group?.id,
  );
  const theOtherPartyName = theOtherParty?.displayName ?? '';
  const fullContractName = [theOtherPartyName, formattedAddress].join(' · ');

  const payee = getPayeeParty(participantParties);
  const payer = getPayerParty(participantParties);
  const inviter = getInviterParty(participantParties);
  const invitee = getInviteeParty(participantParties);

  paymentManager.contract.id = id;
  paymentManager.contract.address = formattedAddress;
  paymentManager.contract.suburbAndState = [suburb, state].filter(Boolean).join(' ');
  paymentManager.contract.contractType = contractType;

  paymentManager.contract.party = theParty ?? null;
  paymentManager.contract.partyName = theParty?.displayName ?? '';
  paymentManager.contract.theOtherParty = theOtherParty ?? null;
  paymentManager.contract.theOtherPartyName = theOtherPartyName;

  paymentManager.contract.payerParty = payer;
  paymentManager.contract.payeeParty = payee;
  paymentManager.contract.inviterParty = inviter;
  paymentManager.contract.inviteeParty = invitee;

  paymentManager.contract.isPayee = payee?.userGroup?.id === profile?.groupRelation?.group?.id;
  paymentManager.contract.isPayer = payer?.userGroup?.id === profile?.groupRelation?.group?.id;
  paymentManager.contract.isInviter = inviter?.userGroup?.id === profile?.groupRelation?.group?.id;
  paymentManager.contract.isInvitee = invitee?.userGroup?.id === profile?.groupRelation?.group?.id;

  paymentManager.contract.participantParties = participantParties;

  paymentManager.contract.contractName = theOtherPartyName;
  paymentManager.contract.fullContractName = fullContractName;
};

export const saveClaimEvidences = async (payment: PaymentStage) => {
  const evidences = sortBy(payment.claim?.evidences ?? [], (evidence) => evidence.fileName);
  const preSignedUrls = await getPreSignedUrls(evidences, pceBucketName);
  savePreSignedUrls(preSignedUrls);
  paymentManager.evidences = evidences;
  paymentManager.documents = evidences.filter(
    (evidence) => evidence.category === EvidenceCategory.DOCUMENT,
  );
  paymentManager.photosAndVideos = evidences.filter(
    (evidence) => evidence.category !== EvidenceCategory.DOCUMENT,
  );
};

export const setClaimDetails = async (data: ClaimDetailsData) => {
  const { claim, contractSummary } = data;
  const { progressPayment, ...restClaim } = claim;

  if (progressPayment) {
    const paymentWithClaim = { ...progressPayment, claim: restClaim };
    const payment = formatProgressPayment(paymentWithClaim, {
      index: 0,
    });
    saveCurrentPayment(payment);
    saveContractSummary(contractSummary);
    paymentManager.claimPayment = progressPayment;
    paymentManager.statuses.isPeriodic = Boolean(claim.workItemPayment);
    paymentManager.statuses.isRetention = claim.type === PaymentClaimType.RETENTION;
    paymentManager.statuses.isOffPlatform = claim.source === ClaimSource.OFF_PLATFORM;
    await saveClaimEvidences(paymentWithClaim);
    return;
  }

  paymentManager.statuses.isRetention = claim.type === PaymentClaimType.RETENTION;
  paymentManager.statuses.isPeriodic = Boolean(claim.workItemPayment);
  paymentManager.statuses.isOffPlatform = claim.source === ClaimSource.OFF_PLATFORM;
  paymentManager.contractSummary = contractSummary;
  paymentManager.claimPayment = progressPayment;
  paymentManager.claimAmount = claim?.amount ?? 0;
  paymentManager.claim = {
    ...claim,
    amount: claim.amount ?? 0,
  };
  paymentManager.paymentBreakdown = claim?.paymentBreakdown;
  const paymentHistories = claim?.paymentHistory || [];
  const claimHistories = claim?.stateHistory || [];
  paymentManager.histories = sortBy([...claimHistories, ...paymentHistories], (item) =>
    item.createdAt.valueOf(),
  ).reverse();
  paymentManager.variations = claim?.variations || [];
  paymentManager.adjustments = claim?.adjustments || [];
  const numberOfVariations = claim.variations?.length ?? 0;
  const numberOfAdjustments = claim.adjustments?.length ?? 0;
  paymentManager.numberOfVariations = numberOfVariations + numberOfAdjustments;

  const evidences = sortBy(claim.evidences || [], (evidence) => evidence.fileName);
  paymentManager.evidences = evidences;
  paymentManager.documents = evidences.filter(
    (evidence) => evidence.category === EvidenceCategory.DOCUMENT,
  );
  paymentManager.photosAndVideos = evidences.filter((evidence) =>
    [EvidenceCategory.PHOTOGRAPH, EvidenceCategory.VIDEO].includes(evidence.category),
  );

  const discounts = claim.discounts || [];
  paymentManager.earlyReleaseDiscounts = discounts.filter(
    (discount) => discount.type === 'EARLY_RELEASE_DISCOUNT',
  );
  paymentManager.numberOfDiscounts = discounts.length;
  savePeriodicWorkItems(claim.workItemPayment);
};

export const setCurrentPayment = (paymentId?: string) => {
  if (!paymentId) return;
  paymentManager.id = paymentId;
  const { payments, provisionalClaims } = contractManager;
  const targetPayment = [...provisionalClaims, ...payments].find(
    (payment) => payment.id === paymentId,
  );
  if (!targetPayment) return;
  Object.assign(paymentManager, targetPayment);
  fixNestedObjectAssign(targetPayment);
  savePeriodicWorkItems(targetPayment.workItemPayment);
};

export const saveCurrentPayment = (payment?: Payment | null) => {
  const targetPayment = payment ?? initialState;
  Object.assign(paymentManager, targetPayment);
  fixNestedObjectAssign(targetPayment);
  savePeriodicWorkItems(targetPayment.workItemPayment);
};

subscribeKey(projectManager, 'requiringActions', (value) => {
  const { id } = paymentManager;
  if (!id) return;
  const requiringActions = value.filter(
    ({ featureItemType, data }) =>
      paymentRequiringActionFeatureTypeSet.has(featureItemType) &&
      data?.progressPaymentId &&
      data.progressPaymentId === id,
  );
  paymentManager.requiringActions = requiringActions;
});
